@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Cookie&family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Fasthand&family=Herr+Von+Muellerhoff&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mr+Dafoe&family=Norican&family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&family=Oswald:wght@200;300;400;500;600;700&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800;1,900&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,300;0,400;0,500;0,900;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'atropos/scss';
@layer base {
  .font-outline-1 {
    -webkit-text-stroke: 1px white;
  }
  .font-outline-2 {
    -webkit-text-stroke: 2px white;
  }
  .font-outline-black-1 {
    -webkit-text-stroke: 1px rgb(0, 0, 0);
  }
  .font-outline-black-2 {
    -webkit-text-stroke: 2px rgb(0, 0, 0);
  }
  .font-outline-4 {
    -webkit-text-stroke: 4px white;
  }
  .ancora {
    overflow-anchor: auto;
  }
  .background-animate {
    background-size: 400%;

    -webkit-animation: AnimationName 9s ease infinite;
    -moz-animation: AnimationName 9s ease infinite;
    animation: AnimationName 9s ease infinite;
  }
  .contactBtn-animate{
    background-size: 400%;

    -webkit-animation: ContactBtn .5s ease linear forwards;
    -moz-animation: ContactBtn .5s ease linear forwards;
    animation: ContactBtn .5s ease linear forwards;
  }
  @keyframes AnimationName {
    0%,
    100% {
      background-position: 40% 50%;
    }
    25%{
      background-position: 50% 100%;
    }
    50% {
      background-position: 60% 100%;
    }
    75%{
      background-position: 20% 80%;
    }
  }
  @keyframes ContactBtn{
    0%{
      background: rgb(107,52,255);
      background: linear-gradient(90deg, rgba(107,52,255,1) 0%, rgba(178,52,255,1) 100%);
    }
    100%{
      background: rgb(107,52,255);
      background: linear-gradient(90deg, rgba(107,52,255,1) 0%, rgba(178,52,255,1) 17%);
    }
  }
} 
html {
  max-width: 100%;
  overscroll-behavior: none
}
body {
  margin: 0;
  font-family: "Noto Sans",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
  max-width: 100%;
  
  
}
body::-webkit-scrollbar {
  width: 12px;
  background-color: 1A1A1A
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(95, 0, 90);
  outline: 1px solid slategrey;
  border-radius: 5rem;
  height: 12rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  overflow-x: hidden;
}
@media only screen and (max-width: 1279px){
  html{
    overflow-x: hidden;
  }
  body{
    overflow: hidden;
  }
  }
